<template>
  <!-- v-if="section.seatLayout" -->
  <div class="container-fluid">
    <div class="row justify-content-center py-3">
      <div class="">
        <div class="fnb-movie-ticket">
          <div class="row mx-0">
            <div class="col-lg-6 col-xl-7 fnb-mt-cut border-bottom-dashed py-2">
              <div v-if="BookingDetails.md_thumbnail_url">
                <img
                  class="img-fluid"
                  width="50%"
                  height="50%"
                  :src="BookingDetails.md_thumbnail_url"
                />
              </div>
              <div v-if="BookingDetails.md_thumbnail_url == null">
                <img
                  src="@/assets/img/placeholder-image.png"
                  class="img-fluid"
                  alt
                />
              </div>
              <div>
                <div class="text-turncate mt-2 font-weight-bold h5">
                  <!-- Final test 20210430 -->
                  {{ BookingDetails && BookingDetails.movie_title }}
                </div>
                <div class="text-blue font-weight-bold">
                  {{ BookingDetails && BookingDetails.lang_name }}
                  <span class="badge badge-primary ml-3">{{
                    BookingDetails && BookingDetails.mf_name
                  }}</span>
                </div>
                <div class="mt-2">
                  <span>{{ BookingDetails.og_show_date | getDay }}</span>
                  <span>|</span>
                  <span
                    >{{ dateParser(BookingDetails.og_show_date, "dateDigit") }}
                    {{
                      " " + dateParser(BookingDetails.og_show_date, "month")
                    }}</span
                  >
                  <span>|</span> <span>{{ BookingDetails.og_show_time }}</span>
                </div>
                <div class="text-blue font-weight-bold mt-2">
                  {{ BookingDetails.screen_name }}
                </div>
                <div class="d-flex">
                  <ul class="list-inline mb-0 seat-seperated-list">
                    <li class="list-inline-item">
                      {{
                        BookingDetails.seats_types &&
                        BookingDetails.seats_types.length > 0
                          ? BookingDetails.seats_types[0].seatType
                          : ""
                      }}
                    </li>
                    -
                    <li class="list-inline-item">{{ BookingDetails.seats }}</li>
                  </ul>
                </div>
                <div class="text-blue font-weight-bold mt-2">Ticket Type</div>
                <div>{{ BookingDetails.SeatGroupType }}</div>
              </div>
              <div class="pos-qrcode-wrapper d-lg-block d-xl-block d-none">
                <img :src="BookingDetails.qrcode" alt />
              </div>
            </div>
            <div
              class="col-lg-6 col-xl-5 border-left-dashed py-2 right-content"
            >
              <div class="col-12 px-0">
                <div class="row justify-content-between">
                  <div class="col-12">
                    <div
                      class="mt-2 font-weight-bold d-table border-bottom font-italic"
                    >
                      MOVIE
                    </div>
                  </div>
                </div>
                <div class="final_align">
                  <div class="">
                    {{ BookingDetails && BookingDetails.movie_title }}
                  </div>
                  <div class="text-right">
                    {{ curr_code | uppercase }}&nbsp;{{
                      BookingDetails.netAmount
                        ? BookingDetails.netAmount.toFixed(2)
                        : BookingDetails.sb_total_amount
                    }}
                  </div>
                </div>
                <div v-if="FnbItems.length > 0">
                  <div class="row justify-content-between">
                    <div class="col-12">
                      <div
                        class="mt-2 font-weight-bold d-table border-bottom font-italic"
                      >
                        F&amp;B
                      </div>
                    </div>
                  </div>
                  <div
                    class="row justify-content-between"
                    v-for="(item, itemIndex) in FnbItems"
                    :key="itemIndex"
                  >
                    <div class="col-8 col-lg-8 col-md-8">
                      {{ item.item_name }} &nbsp;* &nbsp;{{ item.quantity }}
                    </div>
                    <div class="col-4 col-lg-4 col-md-4 text-right">
                      {{ curr_code | uppercase }}&nbsp;{{
                        (item.fp_amount * item.quantity).toFixed(2)
                      }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    BookingDetails.selectedAddons &&
                      BookingDetails.selectedAddons.length > 0
                  "
                >
                  <div class="row justify-content-between">
                    <div class="col-12">
                      <div
                        class="mt-2 font-weight-bold d-table border-bottom font-italic"
                      >
                        Add-Ons
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    v-for="(addOn, addOnIndex) in BookingDetails.selectedAddons"
                    :key="addOnIndex"
                  >
                    <!-- {{item.fp_total_amount * item.quantity}} -->
                    <div class="col-8">
                      {{ addOn.add_on_item_name }} &nbsp;* &nbsp;{{
                        addOn.quantity
                      }}
                    </div>
                    <div class="col-4 text-right">
                      {{ curr_code | uppercase }}&nbsp;{{
                        addOn.add_on_item_price * addOn.quantity
                      }}
                    </div>
                  </div>
                </div>
                <div class="" v-if="charges && charges.length > 0">
                  <div
                    class="row pb-3 justify-content-between"
                    v-for="(Tax, TaxIndex) in charges"
                    :key="TaxIndex + Tax.ct_name"
                  >
                    <div class="col-8">{{ Tax.ct_name }}</div>
                    <div class="col-4 text-right">
                      {{ curr_code | uppercase }}&nbsp;{{
                        parseFloat(Tax.totalTax).toFixed(2)
                      }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    BookingDetails.addonsAdditionalCharges &&
                      BookingDetails.addonsAdditionalCharges.length > 0
                  "
                >
                  <div
                    class="row justify-content-between"
                    v-for="(Tax,
                    TaxIndex) in BookingDetails.addonsAdditionalCharges"
                    :key="TaxIndex + Tax.ct_name"
                  >
                    <div class="col-8">{{ Tax.ct_name }}</div>
                    <div class="col-4 text-right">
                      {{ curr_code | uppercase }}&nbsp;{{
                        parseFloat(Tax.totalTax).toFixed(2)
                      }}
                    </div>
                  </div>
                </div>
                <div class="border-top my-2"></div>
                <div
                  class="row justify-content-between"
                  v-if="BookingDetails.netAmount"
                >
                  <div class="col-5">
                    <div class="my-1 font-weight-bold">Net Total</div>
                  </div>
                  <div class="col-7 text-right">
                    <div class="my-1 font-weight-bold">
                      {{ curr_code }}&nbsp;{{
                        BookingDetails.netAmount.toFixed(2)
                      }}
                    </div>
                  </div>
                </div>
                <div
                  class=""
                  v-if="
                    BookingDetails.AdditionalCharges &&
                      BookingDetails.AdditionalCharges.length > 0
                  "
                >
                  <div
                    class="row justify-content-between"
                    v-for="(charges,
                    chargesIndex) in BookingDetails.AdditionalCharges"
                    :key="chargesIndex"
                  >
                    <div class="col-8">{{ charges.ct_name }} :</div>
                    <div class="col-4 text-right">
                      {{ curr_code | uppercase }}&nbsp;
                      {{
                        charges.pas_is_rebate && charges.pas_is_rebate === "Y"
                          ? -charges.sp_value
                          : charges.sp_value.toFixed(2)
                      }}
                    </div>
                  </div>
                </div>
                <div class="row justify-content-between">
                  <div class="col-5">
                    <div class="my-1 font-weight-bold">Total</div>
                  </div>
                  <div class="col-7 text-right">
                    <div class="my-1 font-weight-bold">
                      {{ curr_code | uppercase }}&nbsp;{{
                        BookingDetails.getFinalAmount
                          ? BookingDetails.getFinalAmount.toFixed(2)
                          : BookingDetails.sb_total_amount
                          ? BookingDetails.sb_total_amount.toFixed(2)
                          : 0
                      }}
                    </div>
                  </div>
                </div>
                <div
                  class="row justify-content-between"
                  v-if="BookingDetails.getStoreLoyalityAmount"
                >
                  <div class="col-5">
                    <div class="my-1 font-weight-bold">Loyalty Amount</div>
                  </div>
                  <div class="col-7 text-right">
                    <div class="my-1 font-weight-bold">
                      {{ curr_code }}&nbsp;{{
                        BookingDetails.getStoreLoyalityAmount
                          ? BookingDetails.getStoreLoyalityAmount
                          : 0
                      }}
                    </div>
                  </div>
                </div>
                <div
                  class="row justify-content-between"
                  v-if="BookingDetails.offerPrice"
                >
                  <div class="col-5">
                    <!-- <div class="my-1 font-weight-bold">Voucher/offer<br><span class="text-muted" style="font-size: 12px;">{{BookingDetails.getSelectedOffer ? BookingDetails.getSelectedOffer.offer_title ? BookingDetails.getSelectedOffer.offer_title : BookingDetails.getSelectedOffer.voucher_title ? BookingDetails.getSelectedOffer.voucher_title : '' : '' }}</span></div> -->
                    <div class="my-1 font-weight-bold">Voucher</div>
                  </div>
                  <div class="col-7 text-right">
                    <div class="my-1 font-weight-bold text-danger">
                      -{{ curr_code }}&nbsp;{{
                        BookingDetails.offerPrice
                          ? parseFloat(BookingDetails.offerPrice).toFixed(2)
                          : 0
                      }}
                    </div>
                  </div>
                </div>
                <div class="row" v-if="BookingDetails.CashCardAmount">
                  <div class="col-5">
                    <div class="my-1 font-weight-bold">Cash Card</div>
                  </div>
                  <div class="col-7 text-right">
                    <div class="my-1 font-weight-bold text-danger">
                      -{{ curr_code }}&nbsp;{{
                        BookingDetails.CashCardAmount
                          ? parseFloat(BookingDetails.CashCardAmount).toFixed(2)
                          : 0
                      }}
                    </div>
                  </div>
                </div>
                <div class="row total-amount justify-content-between">
                  <div class="col-5">
                    <div class="my-1 font-weight-bold">Final Amount</div>
                  </div>
                  <div
                    class="col-7 text-right"
                    v-if="BookingDetails.getFinalAmount"
                  >
                    <div class="my-1 font-weight-bold">
                      {{ curr_code }}&nbsp;{{
                        (
                          parseInt(BookingDetails.getFinalAmount) -
                          parseInt(BookingDetails.getStoreLoyalityAmount)
                        ).toFixed(2)
                      }}
                    </div>
                  </div>
                  <div class="col-7 text-right" v-else>
                    <div class="my-1 font-weight-bold">
                      {{ curr_code }}&nbsp;
                      {{
                        (
                          BookingDetails.sb_total_amount -
                          BookingDetails.offerPrice -
                          BookingDetails.getStoreLoyalityAmount +
                          parseInt(BookingDetails.CashCardAmount)
                        ).toFixed(2)
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pos-qrcode-wrapper mx-auto d-lg-none d-xl-none  py-3">
              <img :src="BookingDetails.qrcode" alt />
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-10 text-center mt-3">
        <button class="btn-blue-lg mr-3">Print</button>
        <button class="btn-blue-lg">Print Receipt</button>
      </div> -->
    </div>
  </div>
</template>
<script>
// import ScreenService from "@/services/ScreenService";
import moment from "moment";
export default {
  props: {
    // setPosFnB: { type: Function }
    BookingDetails: {
      type: Object
    },
    FnbItems: Array,
    curr_code: String,
    charges: Array
  },
  computed: {},
  methods: {
    dateParser(date, type) {
      if (type == "dateDigit") {
        return moment(new Date(date)).format("Do");
      } else if (type == "month") {
        return moment(new Date(date)).format("MMMM");
      }
    }
  }
};
</script>

<style>
.grey {
  background-color: lightgray;
}
.hideScreen {
  height: 0;
  margin-bottom: 0 !important;
  overflow: hidden;
}
.normal-seat {
  display: none !important;
}
.pos-seatlayout table td {
  min-width: 50px;
  max-width: 65px;
  min-height: 50px;
  padding-bottom: 2px;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.pos-seatlayout table td.pos-seatLayoutTitle {
  min-width: 50px;
  max-width: 65px;
  min-height: 50px;
  padding-bottom: 2px;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
}
.fnb-movie-ticket {
  margin-top: 20px;
  box-shadow: rgba(128, 128, 128, 0.68) 1px 1px 3px 1px;
  border-radius: 5px;
  transform: scale(0.6);
  transform-origin: top;
  margin-left: 20px;
}

.fnb-mt-cut {
  grid-gap: 2%;
  display: grid;
  grid-template-columns: 20% 50% 25%;
  align-items: center;
  font-size: 20px;
}
/* .fnb-mt-cut::before {
  top: -13px;
  border-bottom: 2px solid grey;
}
.fnb-mt-cut::after,
.fnb-mt-cut::before {
  content: "";
  width: 25px;
  right: -13px;
  z-index: 22;
  height: 25px;
  display: block;
  position: absolute;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
}
.fnb-mt-cut::after {
  bottom: -13px;
  border-top: 2px solid grey;
} */
.total-amount {
  color: #50adb5;
}

.right-content {
  font-size: 20px;
}

.border-bottom-dashed {
  border-bottom: 1px dashed #969696;
}

@media (max-width: 768px) {
  .fnb-mt-cut {
    grid-template-columns: 35% 65%;
    font-size: 15px;
  }

  .right-content {
    font-size: 15px;
  }

  .pos-qrcode-wrapper {
    width: 60%;
  }
}
</style>
